import * as React from "react"
import {
  Box,
  Center,
  Link as ChakraLink,
  Flex,
  Image,
  Wrap,
  WrapItem,
  Text,
} from "@chakra-ui/react"

// propsはオブジェクト
//
const MoshimoKantan = (props) => {
  const {
    image_url = null,
    title = null,
    rep_url = null,
    amazon = null,
    amazon_beacon = null,
    rakuten = null,
    rakuten_beacon = null,
    yahoo = null,
    yahoo_bcon = null,
  } = props

  const btn_list = [
    { url: amazon, txt: "Amazonで見る", color: "#f79256" },
    { url: rakuten, txt: "楽天市場で見る", color: "#f76956" },
    { url: yahoo, txt: "Yahoo!ショッピングで見る", color: "#66a7ff" },
  ]

  const beacon_list = [amazon_beacon, rakuten_beacon, yahoo_bcon]
  return (
    <Center>
      <Box
        bg="white"
        borderWidth="1px"
        borderColor="#ddd"
        rounded="none"
        p={2}
        m={4}
        w="100%"
      >
        {/* 横長だったらblock */}
        <Flex
          flexWrap="no-wrap"
          display={{ base: "block", sm: "flex" }}
          justify="left"
        >
          {/* 画像のURLが無ければこのブロックを作らない */}
          {/* 画像エリア */}

          {image_url && (
            <Box minW={0} mx="auto" flex="1">
              <ChakraLink href={rep_url} rel="nofollow" isExternal>
                <Center>
                  <Image
                    loading="lazy"
                    fit="scale-down"
                    src={image_url}
                    quality={95}
                    alt=""
                    border={0}
                  />
                </Center>
              </ChakraLink>
            </Box>
          )}

          {/* タイトルとボタンエリア */}
          <Box
            className="nondeco-a-hover nondeco-a"
            mt={{ base: 4, sm: 2 }}
            minW={0}
            flex="2"
            display="block"
          >
            {/* タイトル */}
            <ChakraLink href={rep_url} rel="nofollow">
              <Box
                overflowWrap="break-word"
                fontSize="md"
                fontWeight="bold"
                m="initial"
                textAlign={{ base: "center", sm: "left" }}
              >
                {title}
              </Box>
            </ChakraLink>
            {/* ボタンエリア */}
            <Box>
              <Wrap className="moshimo" mt="1em">
                {btn_list.map(
                  (item) =>
                    item.url && (
                      <WrapItem
                        key={item.url}
                        listStyleType="none"
                        my="5px"
                        minW={{ base: "100%", sm: "200px" }}
                      >
                        <ChakraLink href={item.url} rel="nofollow" isExternal>
                          <Box
                            textAlign="center"
                            py="0.8em"
                            px="1em"
                            bgColor={item.color}
                            color="white"
                            fontSize="xs"
                            fontWeight="bold"
                            inActive="false"
                            _focus={{ boxShadow: "none" }}
                            width={{ base: "100%", sm: "200px" }}
                            borderRadius="sm"
                            overflowWrap="anywhere"
                            _hover={{
                              textDecoration: "none",
                              border: "1px",
                              borderColor: item.color,
                              bgColor: "white",
                              color: item.color,
                            }}
                          >
                            {`${item.txt}`}
                          </Box>
                        </ChakraLink>
                      </WrapItem>
                    )
                )}
              </Wrap>
            </Box>
          </Box>
        </Flex>
        {beacon_list.map(
          (bcon) =>
            bcon && (
              <Image
                src={bcon}
                width="1px"
                height="1px"
                border="0"
                margin="0"
              />
            )
        )}
        <Text fontSize="0.6rem" color="gray.800" textAlign="right">
          商品リンクはもしもアフィリエイト様より提供されたものを利用しています。
        </Text>
      </Box>
    </Center>
  )
}

export default MoshimoKantan
