import { AdsenseInArticle } from "../components/adsense/adsense-in-article";
import A8Text from "../components/for-article/a8net/a8-text";
import AffiliateLink from "../components/for-article/affiliate/affiliate-link";
import MoshimoKantan from "../components/for-article/affiliate/moshimo-kantan";
import { ATag } from "../components/for-article/aTag";
import { BalloonLeft } from "../components/for-article/balloon/balloon-left";
import ErrorCode from "../components/for-article/code/error-code";
import Large from "../components/for-article/decoration/Large";
import Marker from "../components/for-article/decoration/Marker";
import BgColor from "../components/for-article/frame/BgColor";
import Border from "../components/for-article/frame/Border";
import Important from "../components/for-article/frame/Important";
import Reference from "../components/for-article/frame/Reference";
import InBlogCard from "../components/for-article/inner-link/in-blog-card";
import InBlogTitle from "../components/for-article/inner-link/in-blog-title";
import InBlogUrl from "../components/for-article/inner-link/in-blog-url";
import Tweet from "../components/for-article/Tweet";
import { Author } from "../components/for-article/Variables/Author";
import BlogCard from "../components/iframly/BlogCard";
import BlockQuote from "../components/molecules/BlockQuote";

export const shortCode = {
  BlogCard,
  Reference,
  BlockQuote,
  Marker,
  Large,
  Tweet,
  InBlogCard,
  InBlogTitle,
  InBlogUrl,
  AffiliateLink,
  A8Text,
  Border,
  BgColor,
  Important,
  ATag,
  Author,
  AdsenseInArticle,
  BalloonLeft,
  ErrorCode,
  MoshimoKantan,
}
