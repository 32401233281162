import { Box } from "@chakra-ui/react"
import React from "react"

const BlockQuote = (props) => {
  const { children } = props
  const bq = (
    <Box
      as="blockquote"
      position="relative"
      pt="1.5em"
      pb="1em"
      px={{base:"1em", sm:"2em"}}
      boxSizing="border-box"
      bg="gray.100"
      borderRadius="5px"
      lineHeight="1.7"
      mt={5}
      mb={5}
      _before={{
        color: "gray.300",
        display: "inline-block",
        position: "absolute",
        top: "-0.15em",
        left: "15px",
        content: "'“'",
        fontFamily: "serif",
        fontSize: "70px",
        lineHeight: "1",
        fontWeight: "900",
      }}
    >
      {children}
    </Box>
  )

  return bq
}

export default BlockQuote
