import { Center } from "@chakra-ui/react"
import React from "react"

const Tweet = ({ children }) => {
  return (
    <Center>
      <blockquote className="twitter-tweet">{children}</blockquote>
    </Center>
  )
}

export default Tweet
