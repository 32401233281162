import {
  Box,
  Center,
  Flex,
  Image,
  Link as ChakraLink,
  Text,
} from "@chakra-ui/react"
import axios from "axios"
import React, { useEffect, useState } from "react"



const BlogCard = (props) => {
  const url = props.children
  const [post, setPost] = useState("")
  const [err, setErr] = useState(false)
  const apikey=process.env.GATSBY_IFRAMELY_API_KEY

  const req = `https://iframe.ly/api/oembed?url=${url}&api_key=${apikey}`
  useEffect(() => {
    axios
      .get(req)
      .then((res) => {
        setPost(res.data)
        // setHtml(res.data.html)
      })
      .catch(() => setErr(true))
  }, [])

  return (
    <Center>
      {err || typeof post.title === "undefined" ? (
        <Text>
          <ChakraLink href={url} isExternal fontSize="md">
            {url}
          </ChakraLink>
        </Text>
      ) : (
        <ChakraLink href={url} itemProp="url" my="0.5em">
          <article
            className="post-list-item"
            itemScope
            itemType="http://schema.org/Article"
          >
            <Flex
              py={0}
              px={{ base: "0.1em", sm: "1em" }}
              align="center"
              border="1px solid"
              borderColor="gray.400"
              boxShadow="md"
              bgColor="white"
              _hover={{ opacity: 0.7 }}
            >
              {post.thumbnail_url && (
                <>
                  {/* 画像 */}
                  <Box
                    display={{ base: "none", sm: "block" }}
                    my={4}
                    mr="1em"
                    width="150px"
                  >
                    <Image
                      src={post.thumbnail_url}
                      objectFit="cover"
                      width="120px"
                      height="120px"
                      alt="画像"
                    />
                  </Box>
                  <Box
                    display={{ base: "block", sm: "none" }}
                    my={4}
                    mr="1em"
                    width="100px"
                  >
                    <Image
                      src={post.thumbnail_url}
                      objectFit="cover"
                      width="100px"
                      height="100px"
                      alt="画像"
                    />
                  </Box>
                </>
              )}
              {/* タイトル */}
              <Box display="block" flex="1" overflowY="hidden">
                <Box
                  display={{ base: "none", sm: "block" }}
                  fontSize="0.9rem"
                  my="0.2em"
                >
                  {post.title}
                </Box>
                <Box
                  display={{ base: "block", sm: "none" }}
                  fontSize="0.8rem"
                  my="0.2em"
                >
                  {post.title.substring(0, 100)}
                  {post.title.length > 100 ? "..." : ""}
                </Box>
              </Box>
            </Flex>
          </article>
        </ChakraLink>
      )}
    </Center>
  )
}

export default BlogCard

