import { Image, Link as ChakraLink} from "@chakra-ui/react"
import * as React from "react"

const A8Text = ({src, href, children}) => {

    return(
        <>
        <ChakraLink href={href} rel="nofollow">{children}</ChakraLink>
        <Image width="1" height="1" src={src} alt="" display="inline-block"/>
        </>
    )

}

export default A8Text