import * as React from "react"
import {
  Box,
  Center,
  Link as ChakraLink,
  Flex,
  Image,
  Button,
} from "@chakra-ui/react"

const AffiliateLink = ({ url, image_url, title, bcon }) => {
  return (
    <Center>
      {url && (
        <Box
          bg="white"
          borderWidth="1px"
          borderColor="#EDF2F7"
          rounded="none"
          p={2}
          m={4}
          boxShadow="md"
          minW="90%"
        >
          {/* 横長だったらblock */}
          <Flex
            flexWrap="no-wrap"
            display={{ base: "block", md: "flex" }}
            justify="center"
          >
            {/* サムネイルのURLが無ければこのブロックを作らない */}
            {/* 画像エリア */}

            <Box minW={0} mx="auto" flex="1">
              <ChakraLink href={url}>
                <Center>
                  <Image
                    loading="lazy"
                    fit="scale-down"
                    src={image_url}
                    quality={95}
                    alt=""
                    border={0}
                  />
                </Center>
              </ChakraLink>
              <Image
                src={bcon}
                width="1px"
                height="1px"
                border="0"
                margin="0"
              />
            </Box>

            {/* タイトルとボタンエリア */}
            <Box
              mt={{ base: 4, md: 2 }}
              minW={0}
              className="nondeco-a"
              flex="2"
              display="block"
            >
              <ChakraLink href={url}>
                <Box
                  overflowWrap="break-word"
                  fontSize="md"
                  fontWeight="bold"
                  m="initial"
                  textAlign={{ base: "center", md: "left" }}
                >
                  {title}
                </Box>
              </ChakraLink>
              <Box mt="1em" textAlign={{ base: "center", md: "left" }}>
                <ChakraLink href={url}>
                  <Button
                    colorScheme="yellow"
                    my={1}
                    inActive="false"
                    isExternal
                    fontWeight="normal"
                    _focus={{ boxShadow: "none" }}
                  >
                    → Amazonで見る
                  </Button>
                </ChakraLink>
              </Box>
            </Box>
          </Flex>
        </Box>
      )}
    </Center>
  )
}

export default AffiliateLink
