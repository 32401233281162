import { Box } from "@chakra-ui/react"
import React from "react"

const Important = ({ children, colorScheme = "red" }) => {
  const borderColor = `${colorScheme}.500`
  const bgColor = `${colorScheme}.50`


  return (
    <Box
      borderLeft="solid 7px"
      borderColor={borderColor}
      bgColor={bgColor}
      borderRadius="7px"
      my="1.5em"
      px="1em"
      py="1em"
    >
      {children}
    </Box>
  )
}

export default Important
