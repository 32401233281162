import { Box, Text, Link as ChakraLink, Flex, VStack } from "@chakra-ui/react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import * as React from "react"
import { GetSlug2postinfo } from "../../utils/get-slug2postinfo"
import SideHeader from "../atom/side-header"
import ArticleCard from "../molecules/article-card"

const RelatedArticles = ({slugs}) => {

  return (
    <Box mx="auto" >
      <SideHeader>関連記事</SideHeader>
      <VStack spacing="2em">
        {slugs.map((slug) => {
          const postInfo = GetSlug2postinfo(slug)

          return (
            <ArticleCard
              title={postInfo.title}
              slug={slug}
              created={postInfo.created}
              lastModified={postInfo.lastModified}
              ogpImage={postInfo.thumbnail}
              
            />
          )
        })}
      </VStack>
    </Box>
  )
}

export default RelatedArticles
