import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Center,  Flex, Text } from "@chakra-ui/react"
import { GatsbyImage } from "gatsby-plugin-image"

// get-slug2postinfoの処理を共通で切り出したので、時間のあるとき入れ替える。
// ただそのとき、gatsbyImageの"width"（graphQLで取得するときに指定してしまっている。。関連記事用に。。）をどうにかする

const InBlogCard = (props) => {
  const slug = props.children
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
      allMdx {
        edges {
          node {
            frontmatter {
              slug
              title
              modified_date(formatString: "YYYY/M/DD")
              created(formatString: "YYYY/M/DD")
              tags
              category
              description
              thumbnail_image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            id
          }
        }
      }
    }
  `)

  const post = data.allMdx.edges.find(
    (element) => element.node.frontmatter.slug === slug
  )?.node

  const thumbnail = post
    ? post.frontmatter.thumbnail_image &&
      post.frontmatter.thumbnail_image.childImageSharp.gatsbyImageData
    : undefined
  const title = post ? post.frontmatter.title : undefined
  const description = post ? post.frontmatter.description : undefined

  const landscape = false
  return (
    <Center>
      {typeof title === "undefined" ? (
        ""
      ) : (
        <Box
          className="blog-card nondeco-a"
          as="article"
          bg="white"
          borderWidth="1px"
          borderColor="gray.300"
          rounded="none"
          p={2}
          m={4}
          boxShadow="md"
          _hover={{ backgroundColor: "#f4f8f9" }}
          
        >
          <Link to={`/${slug}`}>
            {/* 横長だったらblock */}
            <Flex
              flexWrap="no-wrap"
              display={{ base: "block", sm: "flex" }}
              alignItems="center"
              justify="center"
            >
              {/* サムネイルのURLが無ければこのブロックを作らない */}
              {thumbnail ? (
                <Box minW={0} width="200px" mx="auto">
                  <Center width="200px">
                    {thumbnail && (
                      <GatsbyImage
                        layout="fixed"
                        image={thumbnail}
                        alt={title || "画像"}
                        height={200}
                      />
                    )}
                  </Center>
                </Box>
              ) : (
                ""
              )}
              <Box mx={5} mt={2} minW={0} className="nondeco-a" flex="1">
                <Box overflowWrap="break-word">
                  <Text fontSize="md" fontWeight="bold" m="initial">
                    {title}
                  </Text>
                </Box>
                <Box minW={0}>
                  <Text fontSize="xs" overflowWrap="break-word" m="initial">
                    {description}
                  </Text>
                </Box>
                <Box textAlign="right" minW={0} fontSize="xs">
                  {`${data.site.siteMetadata.siteUrl}/${slug}`}
                </Box>
              </Box>
            </Flex>
          </Link>
        </Box>
      )}
    </Center>
  )
}

export default InBlogCard

