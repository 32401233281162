import { Box, Code } from "@chakra-ui/react"
import React from "react"

const ErrorCode = ({ children }) => {
  return (
    <Code
      px="1em"
      py="1em"
      my="2em"
      bgColor="pink.50"
      color="gray.700"
      fontSize="0.9rem"
      whiteSpace="pre-line !important"
      fontFamily="SFMono-Regular, Consolas, 'Courier New', 'BIZ UDGothic', monospace"
      borderRadius="2px"
      border="1px solid"
      borderColor="orange.300"
      overflowWrap="anywhere"
      overflowX="clip"
    >
      {children}
    </Code>
  )
}

export default ErrorCode
