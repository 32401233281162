import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import { Box, Link as ChakraLink } from "@chakra-ui/react"

const InBlogTitle = (props) => {
  const slug = props.children
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
      allMdx {
        edges {
          node {
            frontmatter {
              slug
              title
              modified_date(formatString: "YYYY/M/DD")
              created(formatString: "YYYY/M/DD")
              tags
              category
              description
              thumbnail_image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            id
          }
        }
      }
    }
  `)
 

  const post = data.allMdx.edges.find(
    (element) => element.node.frontmatter.slug === slug
  )?.node

  const title = post
    ? `${post.frontmatter.title} | ${data.site.siteMetadata.title}`
    : ""

  return post ? (
    <Box as="span">
      <Link to={`/${slug}`}>{title}</Link>
    </Box>
  ) : (
    <ChakraLink fontSize="sm">
      ※ページが見つかりません※
    </ChakraLink>
  )
}

export default InBlogTitle
