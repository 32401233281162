import { Box } from "@chakra-ui/react"
import React from "react"

const BgColor = ({ children, bgColor = "purple.50" }) => {
  return (
    <Box bgColor={bgColor}  my="1.5em" px="0.5em" py="0.5em">
      {children}
    </Box>
  )
}

export default BgColor
