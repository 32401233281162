import { Box } from "@chakra-ui/react"
import React from "react"

const Border = ({
  children,
  fontSize = "md",
  borderColor = "red.400",
  borderWidth = "2px",
  noRounded = false,
  mt="1.5em",
  mb="1.5em",
  px={base: "1.5em", sm: "1.5em"},
  py="1.5em",

}) => {
  const borderRadius = noRounded ? "0px" : "10px"

  return (
    <Box
      border="solid"
      borderColor={borderColor}
      borderRadius={borderRadius}
      borderWidth={borderWidth}
      fontSize={fontSize}
      mt={mt}
      mb={mb}
      px={px}
      py={py}
      className="my-border"
    >
      {children}
    </Box>
  )
}

export default Border

