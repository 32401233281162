import * as React from "react"
import {
  Box,
  Divider,
  Flex,
  Heading,
  Link as ChakraLink,
} from "@chakra-ui/react"
import { graphql} from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import ContentsDate from "../components/molecules/contents-date"
import Profile from "../components/organisms/profile"
import Seo from "../components/seo"
import BlockQuote from "../components/molecules/BlockQuote"
import TagsList from "../components/organisms/tags-list"
import TagLabelHstack from "../components/organisms/tag-label-hstack"
import HatenaArticles from "../components/organisms/hatena-articles"
import BreadcrumbList from "../components/organisms/BreadcrumbList"
import { getCategoryName } from "../utils/getCategoryName"
import { Adsense } from "../components/adsense/Adsense"
import { AdsenseVertical } from "../components/adsense/adsense-vertical"
import { Giscus } from "../components/comments/giscus"
import RelatedArticles from "../components/organisms/related-articles"
import { shortCode } from "../params/mdx-short-codes"

const BlogPost = (props) => {
  const { data, location } = props

  const post = data.allMdx.nodes[0]

  const title = post.frontmatter.title

  const created = post.frontmatter.created
  const modified = post.frontmatter.modified_date[0] || created

  const related = post.frontmatter.related || []

  const eyecatchImage =
    post.frontmatter.hero_image &&
    post.frontmatter.hero_image.childImageSharp.gatsbyImageData

  const ogpImagePath =
    post.frontmatter.thumbnail_image &&
    post.frontmatter.thumbnail_image.childImageSharp.fluid.src

  const categoryId = post.frontmatter.category || "others"

  const categoryName = getCategoryName(categoryId)

  const sideBarContents = [
    <Profile />,
    <AdsenseVertical />,
    <HatenaArticles />,
    <TagsList />,
    <RelatedArticles slugs={related} />,
  ]

  const shortcodes = shortCode

  //   chakraをつかってhtmlタグを書き換えるとき
  //   https://towardsdev.com/build-blog-site-using-gatsby-js-part-3-gatsby-plugin-mdx-a284381d2ec1
  //   const chakraUiComponents = {
  //     h1: (props) => <Heading as="h1" {...props} />,
  //     // p: (props) => <Text lineHeight={2} paddingBottom="4" fontSize="lg" {...props} />,
  //     img: (props) => <Image {...props} />
  //   };

  const chakraUiComponents = {
    blockquote: (props) => <BlockQuote {...props}></BlockQuote>,
    // a: (props) => <ATag {...props}></ATag>
    // h2: (props) => <H2 {...props}></H2>,
  }

  const components = { ...shortcodes, ...chakraUiComponents }

  return (
    <Layout
      location={location}
      sideBarContents={sideBarContents}
      isContentPage={true}
    >
      <Seo
        title={title}
        description={post.frontmatter.description}
        ogpImagePath={ogpImagePath}
      />
      <MDXProvider components={components}>
        <article itemScope itemType="http://schema.org/Article">
          <BreadcrumbList
            lists={[
              { name: "Blog", slug: "/" },
              {
                name: categoryName,
                slug: `/category/${categoryId}`,
              },
              { name: title, slug: "#" },
            ]}
          />
          {/* タグ */}
          <Box py={2} my={4}>
            <TagLabelHstack
              tags={post.frontmatter.tags}
            ></TagLabelHstack>
          </Box>
          {/* タイトル、日時　*/}
          <Box
            as="header"
            mt={{ base: 1, md: 20 }}
            mb={{ base: 5, md: 10 }}
          >
            <Heading
              as="h1"
              fontSize={{ base: "1.5rem", md: "2rem" }}
              lineHeight="1.5em"
              mb={3}
            >
              {title}
            </Heading>
            <ContentsDate created={created} modified={modified} />
          </Box>
          {/* 本文 */}
          <section itemProp="articleBody">
            <Box mt={5} mb="10em" className="blog-post">
              {eyecatchImage && (
                <Box my={4}>
                  <GatsbyImage
                    image={eyecatchImage}
                    alt={post.frontmatter.hero_image_alt || "画像"}
                  />
                </Box>
              )}
              <MDXRenderer>{post.body}</MDXRenderer>
            </Box>
          </section>
        </article>
      </MDXProvider>
      {/* コメント */}
      <Divider />
      <Giscus />
      <Divider />
      {/* コメント・間違いはこちらへセンテンス */}
      <Flex justify="right" mt={2}>
        個別連絡はこちらへ→
        <ChakraLink
          color="#005b99"
          href="https://twitter.com/intent/tweet?screen_name=bun_sugi&ref_src=twsrc%5Etfw"
        >
          Twitter
        </ChakraLink>
        ／
        <ChakraLink
          color="#005b99"
          href="https://docs.google.com/forms/d/e/1FAIpQLScwF2LShfg5VZm_NQ2P0ixTT5-eMsYH6ZJcYUtVhqOp_F71dQ/viewform?usp=sf_link"
        >
          お問い合わせ
        </ChakraLink>
      </Flex>
      {/* 広告 */}
      <Box mx="auto" maxW="80%" minW="0" my="1rem">
        <Adsense />
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(filter: { id: { eq: $id } }) {
      nodes {
        frontmatter {
          slug
          title
          modified_date(formatString: "YYYY/M/DD")
          created(formatString: "YYYY/M/DD")
          tags
          category
          description
          hero_image_alt
          related
          thumbnail_image {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          hero_image {
            childImageSharp {
              gatsbyImageData(height: 500)
            }
          }
        }
        id
        body
        parent {
          ... on File {
            sourceInstanceName
          }
        }
      }
    }
  }
`

export default BlogPost
