import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const Author = () => {
    
    const data = useStaticQuery(
        graphql`
          {
            site {
              siteMetadata {
                author {
                    name
                }
              }
            }
          }
        `
      )

      return <>{data.site.siteMetadata.author.name}</>
}
