import { useStaticQuery, graphql } from "gatsby"

export const GetSlug2postinfo = (slug) => {
  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
        allMdx {
          edges {
            node {
              frontmatter {
                slug
                title
                modified_date(formatString: "YYYY/M/DD")
                created(formatString: "YYYY/M/DD")
                tags
                category
                description
                thumbnail_image {
                  childImageSharp {
                    gatsbyImageData(width:300)
                  }
                }
              }
              id
            }
          }
        }
      }
    `
  )

  const post = data.allMdx.edges.find(
    (element) => element.node.frontmatter.slug === slug
  )?.node

  
  let postInfo = {}
  postInfo.data = data
  postInfo.post = post
  postInfo.thumbnail = post
    ? post.frontmatter.thumbnail_image &&
      post.frontmatter.thumbnail_image.childImageSharp.gatsbyImageData
    : undefined
  postInfo.title = post ? post.frontmatter.title : undefined
  postInfo.description = post ? post.frontmatter.description : undefined
  postInfo.url = `${data.site.siteMetadata.siteUrl}/${slug}`
  postInfo.created = post ? post.frontmatter.created :  undefined
  postInfo.lastModified = post ? (post.frontmatter.modified_date[0] || "") : ""
  postInfo.tags = post ? (post.frontmatter.tags || [] ) :  undefined

  return postInfo
}
