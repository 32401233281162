import { Box } from "@chakra-ui/react"
import React from "react"

const Reference = (props) => {
  const { children } = props
  return (
    <Box
      //   bg="gray.100"
      //   borderLeft="8px"
      //   borderStyle="dotted"
      //   borderColor="gray.300"
      border="1px solid"
      borderColor="gray.100"
      bg="gray.100"
      //   shadow="md"
      px="1em"
      py="0.7em"
      mx="0"
      mt="1rem"
      mb="1.8rem"
      fontSize="sm"
    >
      <Box mb="0.5em" fontWeight="bold">
        参考資料
      </Box>
      {children}
    </Box>
  )
}

export default Reference
