import { Box } from "@chakra-ui/react"
import React from "react"

const Large = ({ children, fontSize = "xl" }) => {
  return (
    
    <Box as="span" fontSize={fontSize}>
      {children}
    </Box>
  )
}

export default Large

// <Box as="p" fontSize={fontSize} display="inline">