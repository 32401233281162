// 記事内広告
import { Box } from "@chakra-ui/react"
import React, { useEffect } from "react"

export const AdsenseInArticle = (props) => {
  const { currentPath } = props

  useEffect(() => {
    if (window) {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    }
  }, [currentPath])

  return (
    <Box bgColor="gray.100" maxW="90%" my="2em">
      <ins
        className="adsbygoogle"
        style={{ display: "block", textAlign: "center" }}
        data-ad-layout="in-article"
        data-ad-client="ca-pub-4570049449223906" // 自サイトのコードに置き換える
        data-ad-slot="5491428777" // 自サイトのスロットに置き換える
        data-ad-format="fluid" // 自由
        data-full-width-responsive="true" // 自由
      />
    </Box>
  )
}
