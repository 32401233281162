import { Box } from "@chakra-ui/react"
import React from "react"

const Marker = ({ children, color = "pink.100" }) => {
  return (
    <Box
      as="p"
      bgGradient={`linear(to-b,transparent 0%,${color} 0%)`}
    //   bgGradient={`linear(to-b,transparent 50%,${color} 0%)`}
      display="inline"
    >
      {children}
    </Box>
  )
}

export default Marker
