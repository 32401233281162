import React from "react"
import { Box } from "@chakra-ui/react"
import { Helmet } from "react-helmet"

export const Giscus = () => {
  return (
    <Box className="giscus">
      <Helmet>
        <script
          src="https://giscus.app/client.js"
          data-repo="bunsugi2/comments"
          data-repo-id="R_kgDOHtBGcA"
          data-category="General"
          data-category-id="DIC_kwDOHtBGcM4CQYk6"
          data-mapping="title"
          data-reactions-enabled="1"
          data-emit-metadata="0"
          data-input-position="top"
          data-theme="light"
          data-lang="ja"
          data-loading="lazy"
          crossorigin="anonymous"
          async
        ></script>
      </Helmet>
    </Box>
  )
}

//   <Text>
//     個人の調査・見解のため間違いがあるかもしれませんが、その場合はコメントいただけますと幸いです。<ChakraLink href="https://github.com/">GitHub</ChakraLink>アカウント（無料）からコメントできます。
//   </Text>
