import { Box, Flex, Text } from "@chakra-ui/react"
import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const BalloonLeft = () => {
  return (
    <Flex
      w="100%"
      mx="0"
      my="2em"
      overFlow="hidden"
      justify="left"
      align="center"
      pl={{ base: "0.3em", md: "1em" }}
      pr={{ base: "0.3em", md: "1em", lg: "4em" }}
    >
      <Box>
        <StaticImage
          className="bio-avatar"
          layout="fixed"
          formats={["auto", "webp", "avif"]}
          src="../../../images/profile-pic.png"
          width={50}
          height={50}
          quality={95}
          alt="プロフィールイメージ"
        />
      </Box>
      <Box>
        <Box
          display="inlineBlock"
          position="relative"
          margin="0 1em"
          padding="1em"
          borderRadius="15px"
          bgColor="#FAF5FF"
          _after={{
            content: '""',
            display: "inlineBlock",
            position: "absolute",
            top: "50%",
            left: "-20px",
            transform: "translateY(-50%)",
            border: "10px solid transparent",
            borderRight: "10px solid #FAF5FF",
          }}
        >
          <Text>
            吹き出し作った！！あいうえおかきくけこさしすせそ I implemented a
            speech bubble.
          </Text>
        </Box>
      </Box>
    </Flex>
  )
}
//   .says:after {
//     content: "";
//     display: inline-block;
//     position: absolute;
//     top: 18px;
//     left: -24px;
//     border: 12px solid transparent;
//     border-right: 12px solid #d7ebfe;
//   }

// .Ballon5 {
//     width: 100%;
//     margin: 1.5em 0;
//     overflow: hidden;
//   }

//   .Ballon5 .faceicon {
//     float: left;
//     margin-right: -90px;
//     width: 80px;
//   }

//   .Ballon5 .faceicon img{
//     width: 100%;
//     height: auto;
//     border: solid 3px #d7ebfe;
//     border-radius: 50%;
//   }

//   .Ballon5 .chatting {
//     width: 100%;
//   }

//   .says {
//     display: inline-block;
//     position: relative;
//     margin: 5px 0 0 105px;
//     padding: 17px 13px;
//     border-radius: 12px;
//     background: #d7ebfe;
//   }

//   .says p {
//     margin: 0;
//     padding: 0;
//   }
