import React from "react"
import { Link } from "@chakra-ui/react"
import { ExternalLinkIcon } from "@chakra-ui/icons"

export const ATag = ({href, children}) => {
  return (
    <Link href={href} isExternal _focus={{ boxShadow: "none" }}>
      {children} <ExternalLinkIcon mx="2px" />
    </Link>
  )
}
